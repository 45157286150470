<template>
  <div class="page">
    <el-container class="main-container">
      <el-menu default-active="2" class="el-aside" :collapse="isCollapse" active-text-color="#ffd04b"
        background-color="#545c64" text-color="#fff" :router="true">
        <h5 class="mb-2" v-show="!isCollapse">xxx后台管理系统</h5>
        <el-menu-item index="/main">
          <el-icon>
            <home-filled />
          </el-icon>
          <span>首页</span>
        </el-menu-item>
        <!-- <el-sub-menu index="1">
          <template #title>
            <el-icon>
              <user />
            </el-icon>
            <span>用户管理</span>
          </template>
          <el-menu-item index="/user">用户列表</el-menu-item>
          <el-menu-item index="/role">角色列表</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <Lock />
            </el-icon>
            <span>权限管理</span>
          </template>
          <el-menu-item index="/permission">权限列表</el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="3">
          <template #title>
            <el-icon>
              <document />
            </el-icon>
            <span>文章管理</span>
          </template>
          <el-menu-item index="/category">分类列表</el-menu-item>
          <el-menu-item index="/article">文章列表</el-menu-item>
        </el-sub-menu> -->


        <el-sub-menu :index="item.id" v-for="item in routerList" :key="item.id"> 
          <template #title>
            <el-icon>
              <document />
            </el-icon>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item v-for="citem in item.children" :key="citem.path" :index="citem.path">{{ citem.name }}</el-menu-item>
        </el-sub-menu>
        


      </el-menu>
      <el-container>
        <el-header>
          <div class="left-slibar">
            <div class="collapse">
              <el-icon @click="handle">
                <expand />
              </el-icon>
            </div>
            <div class="breadcrumb">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="item in getMenuList" :key="item.path" :to="item.path">
                  {{ item.name }}
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <div class="gd-info">
            <span>{{ account }}</span>
            <span class="changepwd" @click="changePwd">密码修改</span>
            <span class="logout" @click="loginOut">退出登陆</span>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-dialog v-model="dialogFormVisible" width="30%" title="修改密码">
      <el-form :model="form">
        <el-form-item label="原密码" :label-width="120">
          <el-input v-model="form.oldpwd"  type="password"  autocomplete="off" />
        </el-form-item>
        <el-form-item label="新密码" :label-width="120">
          <el-input  v-model="form.password"  type="password"  autocomplete="off" />
        </el-form-item>
        <el-form-item label="重复新密码" :label-width="120">
          <el-input v-model="form.repassword"  type="password"  autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="submitPwd">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import { ElMessage,ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      isCollapse: false,
      breadList: [],
      dialogFormVisible: false,
      account:'',
      form: {
        oldpwd: '',
        password: '',
        repassword: '',
        token:(localStorage.getItem('token')||'')
      },
      routerList:(JSON.parse(localStorage.getItem('menu'))||[])
    }
  },
  created(){
     this.account=localStorage.getItem('account');
  },
  methods: {
    submitPwd() {
      const _this=this;
      this.$https.post('api/Common/ChangePwd', _this.form).then(res => { 
        if (res.success) {
           this.dialogFormVisible=false;
           ElMessage({
            message:res.msg,
            type:'success'
           })
        }
      })
    },
    handle() {
      if (this.isCollapse) {
        this.isCollapse = false
      } else {
        this.isCollapse = true
      }
    },
    changePwd() {
      this.dialogFormVisible = true;
    },
    //退出登陆
    loginOut() {
      ElMessageBox.confirm(
        '确定要退出吗?',
        '温馨提醒',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          localStorage.clear();
          this.$router.push('/login');
        }).catch(() => {

        })
    }
  },
  computed: {
    getMenuList() {
      // console.log(this.$route.matched)
      return this.$route.matched;
    }
  }
}

</script>

<style lang="scss" scoped>
.page {
  height: 100%;
}




.el-aside {
  height: 100%;
  max-width: 200px;
}


.el-menu {
  border: none;
}

.gd-asibar {
  width: 100%
}

.main-container {
  width: 100%;
  height: 100%;
}

.el-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}


.left-slibar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.collapse {
  width: 50px;
  display: flex;
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
}


.gd-info {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.gd-info span {
  margin-right: 15px;
  font-size: 14px;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  background: rgb(0, 139, 139);
  text-align: center;
  color: white;
  cursor: pointer;
}


.el-main {
  // background: darkcyan;
}

.mb-2 {
  color: white;
  text-align: center;
}

.changepwd{
  cursor: pointer;
}
</style>